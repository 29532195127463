import ua from './ua.json'
import en from './en.json'
import ru from './ru.json'

export const defaultLocate = 'en'

export const laguages = {
    ua,
    en,
    ru
}